@import url(https://use.typekit.net/yom7ger.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #FE8584;
  --border-color: #ccc;
  --title-font: cooper-black-std, serif;
  --body-font: CircularStd-Book;
}

/* FONTS */
@font-face {
  font-family: "CircularStd-Black";
  src: local("CircularStd-Black"),
    url(/static/media/CircularStd-Black.757b8ac8.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "CircularStd-Book";
  src: local("CircularStd-Book"),
    url(/static/media/CircularStd-Book.90481370.otf) format("opentype");
  font-weight: normal;
}

H1, H2, H3, H4, H5 {
  font-family: cooper-black-std, serif;
  font-family: var(--title-font);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 4px;
}

p, div, span {
  font-family: CircularStd-Book;
  font-family: var(--body-font);
  font-size: 14px;
}

.App {
  text-align: center;
}

.App-logo {
  margin-top: 25px;
  margin-bottom: 0px;
  max-width: 400px;
  pointer-events: none;
}
.App-icon {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 150px;
  pointer-events: none;
}
.App-header {
  background-color: #FE8584;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #DAE0E6;
}

input {
  border: solid .25px;
  border-radius: 4px;
}

/* BUTTONS */
button {
  font-family: CircularStd-Book;
  font-family: var(--body-font);
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px;
  text-decoration: none;
  min-width: 100px;
  margin: 5px;
  border: solid .25px;
  border-radius: 4px;
}

.subscribe {
  background-color: #0FAEAF;
  color: white;
  margin-bottom: 15px;
}

.unsubscribe {
  width: 90%;
  margin-top: 15px;
}

.next-lesson {
  float: right;
}

button.complete {
  background-color: grey;
}

button.complete.hidden {
  display: none;  
}

button.active {
  background-color: pink;
}

button:hover {
  background-color: #F8B327;
}

span.course-count {
  display: inline-block;
  background-color: white;
  width: 15px;
  line-height: 1;
  padding: 1px;
  border-radius: 50%;
  border: black 1px solid;
}

input {
  padding: 8px 16px;
  text-decoration: none;
  margin: 5px;
}

/* NAVIGATION */
/* MAIN NAV BAR */
div.nav {
  background-color: inherit;
  display: block;
  padding: 0;
}

div.nav a {
  display: inline-block;
  font-family: CircularStd-Book;
  font-family: var(--body-font);
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px;
  min-width: 100px;
  text-decoration: none;
}

.nav a.active {
  background-color: pink;
}

.nav a:hover {
  background-color: #F8B327;
}
.nav .main, .nav .session {
  display: inline-block;
  vertical-align: bottom;
}

/* SESSION NAV BAR */
.nav .session {
  display: inline-block;
  vertical-align: middle;
}

.session ul, .dashboard > .user > ul {
  list-style-type: none;
  margin: auto;
  padding: 15px 0px;
}

/* COURSE */
.course {
  display: block;
  overflow: auto;
  margin: 15px auto 15px auto;
  padding: 5px;
  max-width: 800px;
  background-color: white;
  border: #ccc 1px solid;
  border: var(--border-color) 1px solid;
  border-radius: 4px;
}

.course .container {
  display: flex;
  /* flex-flow: row wrap; */
  flex-wrap: wrap;
  flex-direction: row; 
  justify-content: center;
}

.course.sidebar {
  width: 150px;
  border-radius: 4px;
  border: none;
}

/* COURSE NAV BAR */
.course-nav {
  overflow: scroll;
  max-height: 400px;
}

.course-nav ul {
  list-style-type: none;
  padding: 5px;
}

.course-nav ul button {
  width: 90%;
  padding: 3px;
}

/* STYLING FOR NavLink OBJECTS */
a:link, a:visited {
  text-align: center;
  text-decoration: none;
  color: black;
}

/* LESSON STYLING */
.lesson {
  padding-top: 0;
  width: 600px;
  padding: 15px;
  flex-shrink: 1;  
}

.lesson .video, iframe {
  height: 240px;
  width: 426px;
}

.blurb, .dashboard {
  overflow: auto;
  margin: 15px auto 15px auto;
  padding: 15px;
  max-width: 800px;
  /* background-color: white;
   border: var(--border-color) 1px solid; */
  border-radius: 4px;
}

.blurb-title {
  margin: 50px;
}

.blurb h3, .blurb h4 {
  text-align: left;
}

div.dashboard > .user, div.dashboard > .course {
  width: auto;
  margin: 15px auto 15px auto;
  padding: 15px;
  background-color: white;
   border: #ccc 1px solid;
   border: var(--border-color) 1px solid;
  border-radius: 4px;
}

@media only screen and (max-width: 900px){
  .nav .greeting {
    display: none;
  }
   
  .App-icon {
    position: relative;
    top: 25px;
    left: 0;
  }

  .App-logo {
    width: 300px;
  }

  .course.sidebar {
    height: 200px;
  }

  .course, .blurb {
    margin: 15px;
  }
}

@media only screen and (max-width: 450px){  
  .App-logo {
    width: 300px;
  }

  .lesson .video, iframe {
    width: auto;
    height: auto;
  }
}
